<template>
  <v-container
    class="pa-0"
    :class="{ 'pb-5': tvs.length > 1 }"
    fill-height
    grid-list-xl
  >
    <v-container
      justify-center
      align-center
      style="max-width: 350px"
      data-v-step="remote_0"
    >
      <v-card outlined v-show="tvs.length > 1" style="width: 100%">
        <v-card-text class="py-0">
          <remote-control-select></remote-control-select>
        </v-card-text>
      </v-card>
      <template v-for="(item, index) in items">
        <v-row
          justify-center
          align-center
          style="width: 100%"
          :key="index"
          class="ma-0"
        >
          <v-col
            class="d-flex justify-center"
            :class="action.bgClass"
            grow
            v-for="action in item.items"
            :key="action.icon"
          >
            <v-btn
              class="ma-0 d-flex"
              x-large
              @click.prevent="sendCmd({ cmd: action.cmd })"
              @click.native.prevent
              :disabled="!selectedTv.id"
              :color="action.bg"
              v-if="action.icon || action.text"
              :dark="!selectedTv.id ? undefined : action.dark"
              fab
            >
              <v-icon large>{{ action.icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RemoteControlSelect from "@/components/remoteControl/RemoteControlSelect";

export default {
  name: "RemoteControl",
  data() {
    return {
      items: [
        {
          items: [
            {
              bg: "red",
              icon: "mdi-power",
              cmd: "tvOff",
              dark: true,
            },
            {
              icon: "mdi-home",
              cmd: "home",
            },
            {
              icon: "mdi-volume-variant-off",
              cmd: "mute",
            },
          ],
        },
        {
          items: [
            {
              icon: "mdi-volume-plus",
              cmd: "volumeUp",
            },
            {
              icon: "mdi-chevron-up",
              cmd: "up",
            },
            {
              icon: "mdi-plus",
              cmd: "channelUp",
            },
          ],
        },
        {
          items: [
            {
              icon: "mdi-chevron-left",
              cmd: "left",
            },
            {
              icon: "mdi-launch",
              cmd: "ok",
            },
            {
              icon: "mdi-chevron-right",
              cmd: "right",
            },
          ],
        },
        {
          items: [
            {
              icon: "mdi-volume-minus",
              cmd: "volumeDown",
            },
            {
              icon: "mdi-chevron-down",
              cmd: "down",
            },
            {
              icon: "mdi-minus",
              cmd: "channelDown",
            },
          ],
        },
        {
          items: [
            {
              icon: "mdi-reply",
              cmd: "back",
            },
            {
              icon: "mdi-play-pause",
              cmd: "playPause",
            },
            {
              icon: "mdi-stop",
              cmd: "stop",
            },
          ],
        },
        {
          items: [
            {
              icon: "mdi-rewind",
              cmd: "rewind",
            },
            {
              icon: "mdi-pause",
              cmd: "playpause",
            },
            {
              icon: "mdi-fast-forward",
              cmd: "fastForward",
            },
          ],
        },
      ],
    };
  },
  components: {
    RemoteControlSelect,
  },
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
      tvs: ({ remoteControl }) => remoteControl.tvs,
      statusRequestTvs: ({ remoteControl }) => remoteControl.statusRequestTvs,
      selectedTv: ({ remoteControl }) => remoteControl.selectedTv,
    }),
  },
  methods: {
    ...mapActions("remoteControl", ["sendCmd"]),
  },
};
</script>
